<template>
  <div class="error-page">
    <img src="@/assets/images/403.jpg" alt />
    <h1 class="content">{{ $t("errorPage.forbidden.content") }}</h1>
    <p class="note">{{ $t("errorPage.forbidden.note1") }}</p>
    <p class="note">{{ $t("errorPage.forbidden.note2") }}</p>
    <el-button type="primary" class="mt-4" @click="logout">{{
      $t("errorPage.forbidden.otherAccount")
    }}</el-button>
  </div>
</template>

<script>
import { logout } from '@/services/auth'
export default {
  name: 'Forbidden',
  methods: {
    logout() {
      logout()
      this.$router.push({ name: 'Login' }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
